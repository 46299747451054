import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MuiDrawer from '@material-ui/core/Drawer';

import menuItems from './MenuItems';
import styles from '../../../styles/components/Drawer.module.scss';

import { useGlobalContext } from '../../../contexts/global.context';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: 'ease',
      duration: 125,
    }),
    borderColor: '#cccccc',
  },
  paperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: 'ease',
      duration: 125,
    }),
    width: theme.spacing(8),
  },
}));

const Drawer = () => {
  const { drawerOpen } = useGlobalContext();
  const classes = useStyles();
  const drawerStyles = clsx(classes.paper, !drawerOpen && classes.paperClose);

  return (
    <MuiDrawer
      variant='permanent'
      open={drawerOpen}
      classes={{ paper: drawerStyles }}
    >
      <div className={styles.drawer}>{menuItems}</div>
    </MuiDrawer>
  );
};

export default Drawer;
