import React, { useEffect, useRef } from 'react';

import Appbar from './Appbar';
import Drawer from './Drawer';
import DashboardRoutes from '../../containers/DashboardRoutes';

import styles from '../../styles/components/Dashboard.module.scss';
import { useGlobalContext } from '../../contexts/global.context';
import { useLocation } from 'react-router-dom';

const Dashboard = () => {
  const { pathname } = useLocation();
  const { darkTheme } = useGlobalContext();
  const mainRef = useRef<HTMLElement>(null);
  const backgroundColor = { backgroundColor: darkTheme ? '#222222' : '#f2f2f2' };

  useEffect(() => {
    if (!mainRef.current) return;
    mainRef.current.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Appbar />
      <Drawer />
      <main ref={mainRef} className={styles.main} style={backgroundColor}>
        <DashboardRoutes />
      </main>
    </>
  );
};

export default Dashboard;
