import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useGlobalContext } from '../../contexts/global.context';

const Spinner = ({ overlay = false }: Props) => {
  const { darkTheme } = useGlobalContext();
  const overlayBgColor = darkTheme
    ? 'rgb(34, 34, 34, 0.75)'
    : 'rgba(255, 255, 255, 0.75)';

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: overlay ? 'absolute' : 'relative',
        top: overlay ? 0 : undefined,
        left: overlay ? 0 : undefined,
        backgroundColor: overlay ? overlayBgColor : undefined,
        zIndex: overlay ? 2 : undefined,
      }}
    >
      <CircularProgress color='primary' />
    </div>
  );
};

interface Props {
  overlay?: boolean;
}

export default Spinner;
